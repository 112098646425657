/*
| Developed by Starton
| Filename : ProjectCardSkeleton.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import { Box, Card, CardContent, Stack, Skeleton } from "@mui/material";

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProjectCardSkeleton = () => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        <Skeleton
          variant="rectangular"
          width={56}
          height={56}
          sx={{ borderRadius: 1 }}
        />
        <Stack spacing={1}>
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" width={100} />
        </Stack>
      </Box>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Skeleton variant="rectangular" width={100} height={24} />
      </Stack>
    </CardContent>
  </Card>
);
