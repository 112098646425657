/*
| Developed by Starton
| Filename : DashboardLastProject.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import { Box, Typography, Grid2 as Grid, Stack, Button } from "@mui/material";
import Link from "next/link";
import { useProjectsIndex } from "@/modules/project/api/project.hooks";
import { ProjectCard, ProjectCardSkeleton } from "@/modules/project/components";
import { UrlsConfig } from "@/config/urls.config";

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardLastProject = () => {
  const { data: projects, isLoading } = useProjectsIndex({
    limit: 6,
    page: 1,
  });

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" component="h2">
          Latest Projects
        </Typography>
        <Button
          component={Link}
          href={UrlsConfig.webapp.project.list}
          variant="text"
          color="primary"
        >
          View All
        </Button>
      </Box>

      {/* Loading state */}
      {isLoading && !projects ? (
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid size={{ xs: 12, md: 6, lg: 4 }} key={index}>
              <ProjectCardSkeleton />
            </Grid>
          ))}
        </Grid>
      ) : null}

      {/* Empty state */}
      {!projects?.meta.total || !projects?.data ? (
        <Box sx={{ textAlign: "center", py: 8 }}>
          <Typography variant="h6" color="text.secondary">
            No project found
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {projects.data.map((project) => (
            <Grid size={{ xs: 12, md: 6, lg: 4 }} key={project.id}>
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};
