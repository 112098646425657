/*
| Developed by Starton
| Filename : DashboardTwitterTimeline.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import { useTwitterWidget } from "@/modules/dashboard/hooks";

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type OnLoad = () => any;
export type RenderError = (error: Error) => React.ReactNode;
export interface DashboardTwitterTimelineProps {
  dataSource: Record<string, any>;
  options?: Record<string, any>;
  onLoad?: OnLoad;
  renderError?: RenderError;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const TwitterTimelineContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
  width: "100%",
  maxHeight: "400px",
  boxSizing: "border-box",
  overflowX: "hidden",
  overflowY: "auto",

  [theme.breakpoints.up("lg")]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxHeight: "100vh",
  },

  "& iframe": {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardTwitterTimeline: React.FC<
  DashboardTwitterTimelineProps
> = ({ dataSource, onLoad, renderError }) => {
  const theme = useTheme();
  const { ref, error } = useTwitterWidget(
    "createTimeline",
    dataSource,
    {
      width: "100%",
      chrome: "transparent noscrollbar",
      theme: "dark",
      dnt: true,
      linkColor: theme.palette.background.default,
    },
    onLoad || (() => {}),
  );

  return (
    <TwitterTimelineContainer ref={ref}>
      {error && renderError ? renderError(error) : null}
    </TwitterTimelineContainer>
  );
};
