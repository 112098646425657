/*
| Developed by Dirupt
| Filename : dashboard-utils.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

"use client";

import loadjs from "loadjs";

const twScriptUrl = "https://platform.twitter.com/widgets.js";
const twScriptWindowFieldName = "twttr";
const twScriptName = twScriptWindowFieldName;

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function loadTwitterLibrary() {
  if (!loadjs.isDefined(twScriptName)) {
    loadjs(twScriptUrl, twScriptName);
  }
}

export function twWidgetFactory() {
  return new Promise((resolve, reject) => {
    const rejectWithError = () =>
      reject(new Error("Could not load remote twitter widgets js"));

    loadTwitterLibrary();

    loadjs.ready(twScriptName, {
      success: () => {
        // Ensure loaded
        // @ts-ignore
        const twttr = window[twScriptWindowFieldName];
        if (!twttr || !twttr.widgets) {
          rejectWithError();
        }
        resolve(twttr.widgets);
      },
      error: rejectWithError,
    });
  });
}

// @ts-ignore
export function removeChildrenWithAttribute(node, attribute) {
  if (node) {
    // @ts-ignore
    node.querySelectorAll("*").forEach((child) => {
      if (child.hasAttribute(attribute)) {
        child.remove();
      }
    });
  }
}

// @ts-ignore
export function cloneShallow(value: any) {
  return typeof value === "object" ? Object.assign({}, value) : value;
}
